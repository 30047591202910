<template>
  <div class="detail">
    <!-- <title-top>商品详情</title-top> -->

    <div class="row position_r banner">
      <detail-swiper :banners="res.images" class="w100"></detail-swiper>
      <img
        @click="onBack"
        class="imgsize48 position_a"
        style="left: .4rem; top: .666667rem;"
        src="~images/backy.png"
        alt
      />
    </div>
    <div class="row align-center between bg-white">
      <div class="row baseline mt33 mb20">
        <div class="row baseline font36 f-F62341 ml30">
          <span class="font24">到手价￥</span>
          <span class="fontwb">{{price}}</span>
        </div>
        <span
          class="font24 f-999999 ml25"
          v-if="false"
        >原价￥{{res.min_normal_price/100 || res.reserve_price}}</span>
      </div>
      <div
        class="row align-center h40 font24 f-FFFFFF fanlbg mr30 mt33 mb20"
        v-if="false"
      >省{{res.estimated_commission}}</div>
    </div>
    <div class="bg-white">
      <p class="font28 f-1A1A1A ellipsis ml30 mr30 mb30">{{res.name}}</p>
    </div>
    <!--往下继续写-->
    <div class="row between align-center mt20 bg-white h72">
      <div class="row flex-center">
        <span class="font28 f-999999 ml30 mr50">规格</span>
        <span class="font28 f-333333" v-if="num">数量：×{{num}}</span>
      </div>
      <img class="imgsize32 mr25" src="~images/nextbtn.png" alt />
    </div>
    <div class="line"></div>
    <!-- 商品信息图片 -->
    <div class="bg-white desc column" v-html="res.description"></div>
    <div class="h146"></div>
    <div class="row align-center bg-white h118 w100 position_f" style="bottom:0; max-width:750px;">
      <div class="row flex-center" style="width:2.346667rem;" @click="onCartList">
        <img class="imgsize96" src="~images/cart.png" alt />
      </div>
      <div class="flex1 row flex-center">
        <span class="row flex-center font32 f-white bg-FF8E61 border-l" @click="onAddCart">加入购物车</span>
        <span class="row flex-center font32 f-white bg-F62341 border-r" @click="onBuy">立即购买</span>
      </div>
    </div>
    <van-sku
      v-model="isShow"
      :sku="sku"
      :goods="goods"
      :goods-id="goodsId"
      :hide-stock="sku.hide_stock"
      @buy-clicked="onBuyClicked"
      @add-cart="onAddCartClicked"
      @sku-selected="skuSelected"
    />
  </div>
</template>

<script>
// @ is an alias to /src
import Vue from "vue";
import { Sku } from "vant";
import { Toast } from "vant";

Vue.use(Toast);
Vue.use(Sku);
import DetailSwiper from "./childComps/DetailSwiper";
import { dashboard } from "network/igou";
import { products, productsDetail, addCart } from "network/igou2";
import { addCart3 } from "network/igou3.js";

export default {
  name: "Detail",
  data() {
    return {
      num: 0,
      res: {
        id: 1,
        category_id: 7,
        name: "",
        price: "",
        range_price: "",
        description: "",
        highest_sharing_reward: "",
        images: [],
        specifications: [],
        stock_keeping_units: [ ],
        video: {
          id: 1,
          title: "张世杰",
          scrolling_list_position: 1,
          content_url:
            "http://images.staging.wanteyun.com/development/images/national_porcelain_hosted_video/content/12/%E9%86%B4%E9%99%B5.mp4",
          cover_frame_image_url:
            "http://images.staging.wanteyun.com/development/images/national_porcelain_hosted_video/content/12/%E9%86%B4%E9%99%B5.mp4?vframe/jpg/offset/3"
        }
      },
      banners: [],
      goods_id: this.$route.query.id ? this.$route.query.id : 1,
      isShow: false,

      price: "0.00",
      stock_keeping_units: [],
      sku: {
        tree: [{
      k: '颜色', // skuKeyName：规格类目名称
      k_s: 's1', // skuKeyStr：sku 组合列表（下方 list）中当前类目对应的 key 值，value 值会是从属于当前类目的一个规格值 id
      v: [
        {
          id: '1', // skuValueId：规格值 id
          name: '红色', // skuValueName：规格值名称
          imgUrl: 'https://img.yzcdn.cn/1.jpg', // 规格类目图片，只有第一个规格类目可以定义图片
          previewImgUrl: 'https://img.yzcdn.cn/1p.jpg', // 用于预览显示的规格类目图片
        },
        {
          id: '2',
          name: '蓝色',
          imgUrl: 'https://img.yzcdn.cn/2.jpg',
          previewImgUrl: 'https://img.yzcdn.cn/2p.jpg',
        }
      ],
      largeImageMode: true, //  是否展示大图模式
    }],
        // 所有 sku 的组合列表，比如红色、M 码为一个 sku 组合，红色、S 码为另一个组合
        list: [
          {
      id: 2259, // skuId
      s1: '1', // 规格类目 k_s 为 s1 的对应规格值 id
      price: '100', // 价格（单位分）
      stock_num: 110 // 当前 sku 组合对应的库存
    },{
      id: 2258, // skuId
      s1: '2', // 规格类目 k_s 为 s1 的对应规格值 id
      price: '400', // 价格（单位分）
      stock_num: 110 // 当前 sku 组合对应的库存
    }
        ],
        price: "1.00", // 默认价格（单位元）
        stock_num: 227, // 商品总库存
        collection_id: 2261, // 无规格商品 skuId 取 collection_id，否则取所选 sku 组合对应的 id
        none_sku: false, // 是否无规格商品
        messages: [],
        hide_stock: true // 是否隐藏剩余库存
      },
      goodsId: this.$route.query.id ? this.$route.query.id : 1,
      goods: {
        title: "",
        picture: ""
      }
    };
  },

  components: {
    DetailSwiper
  },
  created() {
    let token = this.$route.query.token;
    if (token) {
      localStorage.setItem("token", token);
      this.token = token;
    }
  },
  mounted() {
    //this.setSku(this.res);
    this._productsDetail();
  },

  methods: {
    skuSelected(selectedData){
      console.log(selectedData);
      this.price = selectedData.selectedSkuComb.price /100;
    },
    onBuyClicked(skuData) {
      dashboard().then(res => {
        console.log(skuData);
        this.num = skuData.selectedNum;
        this.isShow = false;
        this.$router.push({
          path: "/zyorder",
          query: {
            sku_id: skuData.selectedSkuComb.id,
            num: skuData.selectedNum
          }
        });
      });
    },
    onAddCartClicked() {
      /*
      if (this.token) {
        this._addCart3();
      } else {
        this._addCart();
      }*/
      this._addCart();
      //this.isShow = false;
    },
    onHid() {},
    onBuy() {
      this.isShow = true;
    },
    _productsDetail() {
      productsDetail(this.goods_id).then(result => {
        console.log(result);
        let res = result.data;
        this.res = result.data;
        this.stock_keeping_units = result.data.stock_keeping_units;
        this.price = this.res.stock_keeping_units[0].price;
        this.item_id = this.res.stock_keeping_units[0].id;
        this.setSku(res);
      });
    },
    setSku(res) {
      let picture =
        res.images.length > 0
          ? res.images[0].url
          : "https://img.yzcdn.cn/1.jpg";
      //规格 商品信息
      this.goods = { title: res.name, picture };
      let tree = [];
      let list = [];
      let price = "0.00";
      let stock_num = 1000000;
      let collection_id = 0;
      let none_sku = false;
      let messages = [];
      let hide_stock = true;
      let specifications = res.specifications;
      let stock_keeping_units = res.stock_keeping_units;
      price = res.stock_keeping_units[0].price;
      if (specifications.length > 0) {
        //有规格商品
        specifications.forEach((item, index) => {
          let k = item.name;
          let v = [];
          let k_s = "s" + (index + 1);
          item.values.forEach(child => {
            let tmp = {
              id: child.id + "", // skuValueId：规格值 id
              name: child.content, // skuValueName：规格值名称
              imgUrl: child.image_url, // 规格类目图片，只有第一个规格类目可以定义图片
              previewImgUrl: child.image_url // 用于预览显示的规格类目图片
            };
            v.push(tmp);
          });
          tree.push({ k, v, k_s });
        });
        stock_keeping_units.forEach((item, index) => {
          let id = item.id;
          let price = parseFloat(item.price) * 100;
          let stock_num = 100000;
          let tmp = { id, price, stock_num };
          item.specification_value_ids.forEach(child => {
            tmp[this.getPosition(child, tree)] = child;
          });
          list.push(tmp);
        });
        none_sku = false; // 是否无规格商品
      } else {
        //无规格时
        if (res.stock_keeping_units[0].specification_value_ids.length == 0) {
          collection_id = res.stock_keeping_units[0].id;
        }
        none_sku = true; // 是否无规格商品
      }
      this.sku = {
        tree,
        list,
        price,
        stock_num,
        collection_id,
        none_sku,
        messages,
        hide_stock
      };
      console.log(this.sku);
    },
    getPosition(id, tree) {
      let res = "0";
      tree.forEach((item, index) => {
        item.v.forEach(child => {
          if (id + "" == child.id) {
            res = item.k_s;
          }
        });
      });
      return res;
    },
    _addCart3() {
      addCart3(this.res.stock_keeping_units[0].id, 1)
        .then(result => {
          console.log(result);
          //this.$toast.show("已加入购物车");
          Toast("已加入购物车");
          this.isShow = false;
        })
        .catch(err => {
          console.log(err);
          let obj = { status: 401, error: err.response.data.error || "" };
          if (err.response.status == 401) {
            if (this.isAndroid()) {
              window.app.cbMessage(JSON.stringify(obj));
            } else if (this.isiOS()) {
              window.webkit.messageHandlers.cbMessage.postMessage(
                JSON.stringify(obj)
              );
            }
          }
        });
    },
    _addCart() {
      addCart(this.res.stock_keeping_units[0].id, 1)
        .then(result => {
          console.log(result);
          //this.$toast.show("已加入购物车");
          Toast("已加入购物车");
          this.isShow = false;
        })
        .catch(err => {
          console.log(err);
          console.log("------");
          if (err.response.status == 401) {
            this.$router.replace({
              path: "/login"
            });
          }
        });
    },
    onCartList() {
      this.$router.push("/zycart");
    },
    onAddCart() {
      /*
      if (this.token) {
        this._addCart3();
      } else {
        this._addCart();
      }
      */
      this._addCart();
    },
    onHome() {
      this.$router.push("/home");
    },
    onBack() {
      this.$router.back();
    }
  }
};
</script>
<style >
/*
.desc p ,.detail img {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  flex-direction: column !important;
}
*/
.desc img{
  display: block;
  width: 100%;
  height: auto;
  background-size: cover;
  background-position: center 0;
  margin: 0;
  padding: 0;
}
.halftimg {
  width: 1.173333rem;
  height: 0.533333rem;
  background: url(~images/halftk.png) no-repeat;
  background-size: 1.173333rem 0.533333rem;
}
.fanlbg {
  background: url(~images/ygfl.png) no-repeat;
  background-size: 100% 0.533333rem;
  padding-left: 0.346667rem;
  padding-right: 0.173333rem;
}
.yhqsize {
  width: 9.146667rem;
  height: 2.213333rem;
}
.border-l {
  width: 3.466667rem;
  height: 1.28rem;
  border-radius: 0.666667rem 0 0 0.666667rem;
}
.border-r {
  width: 3.466667rem;
  height: 1.28rem;
  border-radius: 0 0.666667rem 0.666667rem 0;
}
.bgsize {
  padding: 0.16rem;
}
.biankuang {
  border: 1px solid #ebebeb;
  width: 0.746667rem;
  height: 0.693333rem;
}
.szsize {
  width: 1.28rem;
  height: 0.693333rem;
}
</style>
